<template lang='pug'>
.pagination
  .pagination__cont.cont
    ul.pagination__list
      li.pagination__item(v-for="page in pagesArray", :key="page" )
        span.pagination__link(v-if="page === currentPage") {{page}}
        span.pagination__dots(v-else-if="page === '...'") {{page}}
        template(v-else)
          router-link.pagination__link(v-if="currentLocale === 'en'", :to="{ name: $route.name, query: { page } }") {{page}}
          router-link.pagination__link(v-else, :to="{ name: $route.name, params: { locale : currentLocale }, query: { page } }") {{page}}
</template>

<script>
import '@/assets/styles/components/pagination.sass'

export default {
  name: 'Pagination',
  props: {
    pages: Number,
    currentPage: Number
  },
  computed: {
    pagesArray () {
      if (this.pages <= 7) {
        return Array(this.pages).fill(1).map((number, i) => i + 1)
      } else if (this.currentPage <= 4 && this.currentPage <= this.pages - 4) {
        return [1, 2, 3, 4, 5, '...', this.pages]
      } else if (this.currentPage > 4 && this.currentPage < this.pages - 3) {
        return [1, '...', this.currentPage - 1, this.currentPage, this.currentPage + 1, '...', this.pages]
      } else {
        return [1, '...', this.pages - 4, this.pages - 3, this.pages - 2, this.pages - 1, this.pages]
      }
    },
    currentLocale () {
      return this.$i18n.locale
    }
  }
}
</script>
