<template lang="pug">
.gallery
  .gallery__cont.cont
    .gallery__title.title {{galleryName}}
    .gallery__list
      picture.gallery__picture(v-for="(image, i) in images", :key="i")
        source(:scrset="`${image.w328} 1x, ${image.w656} 2x`", type="image/webp", media="(max-width: 360px)")
        source(:scrset="`${image.w293} 1x, ${image.w586} 2x`", type="image/webp", media="(max-width: 638px)")
        source(:scrset="`${image.w301} 1x, ${image.w602} 2x`", type="image/webp", media="(max-width: 1022px)")
        source(:scrset="`${image.w281} 1x, ${image.w562} 2x`", type="image/webp", media="(max-width: 1278px)")
        source(:scrset="`${image.w311} 1x, ${image.w622} 2x`", type="image/webp", media="(max-width: 1400px)")
        source(:scrset="`${image.w438} 1x, ${image.w657} 1.5x`", type="image/webp")
        source(:scrset="`${image.j438} 1x, ${image.j657} 1.5x`")
        img.gallery__image(:src="`${image.j438}`", alt="", loading="lazy")
    a.gallery__link.button Показать еще
</template>

<script>
import '@/assets/styles/components/gallery.sass'
export default {
  name: 'Gallery',
  props: {
    galleryName: String,
    images: Array
  }
}
</script>
