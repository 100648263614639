<template lang='pug'>
pool-info(v-if="poolInfo", :info = "poolInfo")
pagination(v-if="pages > 1", :current-page="page", :pages="pages")
gallery(:gallery-name="galleryName", :images="images", v-if="images.length")
</template>

<script>
import PoolInfo from '../components/tables/PoolInfo'
import Gallery from '../components/gallery/Gallery'
import { getPool } from '../assets/scripts/API'
import Pagination from '../components/Pagination'
import { addMeta } from '../assets/scripts/add-meta'
import { useI18n } from 'vue-i18n'

export default {
  name: 'PoolPage',
  components: { Pagination, Gallery, PoolInfo },
  data () {
    return {
      blockName: '',
      poolInfo: null,
      description: '',
      pages: 1,
      page: 1,
      galleryName: 'Примеры интерфейса',
      images: [],
      coins: []
    }
  },
  methods: {
    async getDataPage (slug, page = 1) {
      const data = await getPool(slug, page)
      if (data.status === 'error') return await this.$router.push({ name: 'Error' })
      this.poolInfo = data
      this.blockName = data.name
      this.coins = data.coins
    }
  },
  async mounted () {
    await this.getDataPage(this.$route.params.slug)
    addMeta(
      this.t('title', [this.blockName]),
      this.t('description', [this.blockName, this.coinsForDescription])
    )
  },
  async beforeRouteEnter (to, from, next) {
    await getPool(to.params.slug)
    next()
  },
  async beforeRouteUpdate (to, from, next) {
    this.page = +to.query.page
    await this.getDataPage(to.params.slug, this.page)
    next()
  },
  computed: {
    coinsForDescription () {
      const array = []
      for (let i = 0; i < this.coins.length && i < 5; i++) {
        array.push(this.coins[i].symbol)
      }
      return array.join(', ')
    }
  },
  watch: {
    '$i18n.locale' () {
      addMeta(
        this.t('title', [this.blockName]),
        this.t('description', [this.blockName, this.coinsForDescription])
      )
    }
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
      messages: {
        ru: {
          title: '{0} выбор монет на майнинг пуле',
          description: 'Майнинг монет на {0}, поддерживаемые криптовалюты: {1}'
        },
        en: {
          title: '{0} selection of coins on the mining pool',
          description: 'Mining coins on {0}, supported cryptocurrencies: {1}'
        },
        es: {
          title: '{0} selección de monedas en el pool de minería',
          description: 'Minería de monedas en {0}, criptomonedas admitidas: {1}'
        },
        pt: {
          title: '{0} seleção de moedas no pool de mineração',
          description: 'Mineração de moedas em {0}, criptomoedas suportadas: {1}'
        },
        de: {
          title: '{0} Auswahl an Coins im Mining-Pool',
          description: 'Mining von Coins auf {0}, unterstützte Kryptowährungen: {1}'
        },
        fr: {
          title: '{0} sélection de pièces sur le pool de minage',
          description: 'Miner des pièces sur {0}, crypto-monnaies prises en charge: {1}'
        },
        it: {
          title: '{0} selezione di monete nel pool minerario',
          description: 'Monete minerarie su {0}, criptovalute supportate: {1}'
        },
        pl: {
          title: '{0} wybór monet w puli wydobywczej',
          description: 'Wydobywanie monet na {0}, obsługiwane kryptowaluty: {1}'
        },
        zh: {
          title: '{0} 礦池上的硬幣選擇',
          description: '在 {0} 上挖掘硬幣，支持的加密貨幣：{1}'
        },
        ja: {
          title: '{0}マイニングプールでのコインの選択',
          description: '{0}でのコインのマイニング、サポートされている暗号通貨：{1}'
        }
      }
    })
    return { t }
  }
}
</script>
