<template lang='pug'>
.info(v-if="info")
  .info__cont.cont
    h1.info__title.title {{info.name}}
    .info__table-box
      coins-table(v-if="info.coins", :coins="info.coins")
    p.info__description.text(v-if="info.description") {{info.description}}
</template>

<script>
import '@/assets/styles/components/info.sass'
import CoinsTable from './CoinsTable'
export default {
  name: 'PoolInfo',
  components: { CoinsTable },
  props: {
    info: Object
  },
  methods: {
    goToCoinPage (slug) {
      if (this.$i18n.locale === 'en') {
        this.$router.push({ name: 'Coin', params: { slug } })
      } else {
        this.$router.push({ name: 'Coin', params: { slug, locale: this.$i18n.locale } })
      }
    }
  }
}
</script>
